import React from 'react'
import ButtonComponent from '../components/ButtonComponent'

export default function Duolingo() {
  return (
    <div className="container py-5 mt-5">
      <div className="d-flex justify-content-between align-items-center">
        <h1 className="text-center">Duolingo English Test</h1>
        <ButtonComponent />
      </div>
      {/* What is Duolingo English Test */}
      <section className="mb-5">
        <h2 className="text-primary">What is Duolingo English Test?</h2>
        <p>
          The Duolingo English Test is an online English proficiency test designed to assess the language skills of non-native English speakers. It is accepted by thousands of universities, colleges, and organizations worldwide. The test evaluates your ability in reading, writing, speaking, and listening, all in a short, convenient format that can be taken from home.
        </p>
      </section>

      {/* Why Choose Duolingo English Test */}
      <section className="mb-5">
        <h2 className="text-primary">Why Choose Duolingo English Test?</h2>
        <ul className="list-group">
          <li className="list-group-item">
            <i className="bi bi-check-circle me-2 text-success"></i>
            <strong>Convenient:</strong> Take the test from the comfort of your own home at any time.
          </li>
          <li className="list-group-item">
            <i className="bi bi-globe me-2 text-success"></i>
            <strong>Global Recognition:</strong> Accepted by over 4,000 universities and institutions worldwide, including in the US, UK, Canada, and Australia.
          </li>
          <li className="list-group-item">
            <i className="bi bi-clock me-2 text-success"></i>
            <strong>Short Duration:</strong> The test lasts approximately 1 hour, making it a quick and efficient option.
          </li>
          <li className="list-group-item">
            <i className="bi bi-dollar me-2 text-success"></i>
            <strong>Affordable:</strong> Duolingo English Test is significantly cheaper than traditional tests like IELTS or TOEFL.
          </li>
        </ul>
      </section>

      {/* Duolingo Test Structure */}
      <section className="mb-5">
        <h2 className="text-primary">Duolingo Test Structure</h2>
        <p>
          The Duolingo English Test is designed to assess all four language skills: reading, writing, speaking, and listening. The test includes the following sections:
        </p>
        <ul className="list-group">
          <li className="list-group-item">
            <i className="bi bi-person-circle me-2 text-info"></i>
            <strong>Introductory Questions:</strong> You will answer a few personal questions to help with the system's identification process.
          </li>
          <li className="list-group-item">
            <i className="bi bi-file-earmark-text me-2 text-info"></i>
            <strong>Reading Section:</strong> Includes reading passages with a set of comprehension questions to evaluate your reading skills.
          </li>
          <li className="list-group-item">
            <i className="bi bi-pencil-square me-2 text-info"></i>
            <strong>Writing Section:</strong> This section tests your ability to write responses to a given prompt.
          </li>
          <li className="list-group-item">
            <i className="bi bi-headset me-2 text-info"></i>
            <strong>Listening Section:</strong> You will listen to recordings and answer questions to assess your listening skills.
          </li>
          <li className="list-group-item">
            <i className="bi bi-mic me-2 text-info"></i>
            <strong>Speaking Section:</strong> In this part, you will record your responses to prompts to evaluate your speaking abilities.
          </li>
        </ul>
      </section>

      {/* Duolingo Test Scores */}
      <section className="mb-5">
        <h2 className="text-primary">Duolingo Test Scores</h2>
        <p>
          The Duolingo English Test provides a score between 10 and 160, which is divided into four skill areas:
        </p>
        <ul className="list-group">
          <li className="list-group-item">
            <i className="bi bi-bar-chart me-2 text-warning"></i>
            <strong>Overall Score:</strong> The overall score ranges from 10 to 160, based on your performance across all sections.
          </li>
          <li className="list-group-item">
            <i className="bi bi-person-lines-fill me-2 text-warning"></i>
            <strong>Speaking and Writing Score:</strong> A separate score is given for these sections, assessing your ability to communicate verbally and in writing.
          </li>
          <li className="list-group-item">
            <i className="bi bi-volume-up me-2 text-warning"></i>
            <strong>Listening and Reading Score:</strong> This score is based on your ability to comprehend spoken and written English.
          </li>
        </ul>
        <p>
          The Duolingo test also includes an adaptive scoring algorithm that adjusts the difficulty of questions based on your responses. This ensures the test accurately reflects your English proficiency.
        </p>
      </section>

      {/* Benefits of Taking the Duolingo English Test */}
      <section className="mb-5">
        <h2 className="text-primary">Benefits of Taking the Duolingo English Test</h2>
        <ul className="list-group">
          <li className="list-group-item">
            <i className="bi bi-lightning me-2 text-danger"></i>
            <strong>Fast Results:</strong> Get your results within 48 hours of taking the test, making it a quick option for students and applicants.
          </li>
          <li className="list-group-item">
            <i className="bi bi-globe me-2 text-danger"></i>
            <strong>Global Access:</strong> You can take the test from anywhere in the world, at any time, using a computer with a microphone and camera.
          </li>
          <li className="list-group-item">
            <i className="bi bi-check-circle me-2 text-danger"></i>
            <strong>Easy Submission:</strong> Send your Duolingo score to multiple institutions for free.
          </li>
          <li className="list-group-item">
            <i className="bi bi-currency-dollar me-2 text-danger"></i>
            <strong>Affordable Pricing:</strong> Duolingo English Test costs significantly less than other language proficiency tests like IELTS or TOEFL.
          </li>
        </ul>
      </section>

      {/* How to Prepare for Duolingo English Test */}
      <section className="mb-5">
        <h2 className="text-primary">How to Prepare for Duolingo English Test</h2>
        <p>
          Preparation for the Duolingo English Test should focus on practicing all four language skills: speaking, listening, reading, and writing. Here are some tips to help you succeed:
        </p>
        <ul className="list-group">
          <li className="list-group-item">
            <strong>Practice with Duolingo's Free Resources:</strong> Duolingo offers a free practice test and various language learning tools that can help you prepare.
          </li>
          <li className="list-group-item">
            <strong>Engage in English Conversations:</strong> Practice speaking English with friends, tutors, or through language exchange programs.
          </li>
          <li className="list-group-item">
            <strong>Watch English Media:</strong> Listen to podcasts, watch movies, and read articles in English to improve your comprehension skills.
          </li>
          <li className="list-group-item">
            <strong>Write Regularly:</strong> Practice writing essays, responses, or journals in English to improve your writing skills.
          </li>
          <li className="list-group-item">
            <strong>Take Practice Tests:</strong> Use Duolingo’s practice resources to familiarize yourself with the test format and timing.
          </li>
        </ul>
      </section>
    </div>
  )
}
